import React, {useState} from 'react';
import {
    Col,
    Card,
    Form
} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const buildAddressOptions = (addressResponse) => {
let options = [];
if(addressResponse) {
    addressResponse.forEach(address => {
      let streetArea =  address.street || address.neighbourhood;
      let displayText = [address.flat, address.buildingNumber, streetArea, address.town]
                        .filter(Boolean)
                        .reduce((a,b) => `${a} ${b}`, '');
      displayText += `, ${address.state}`;
      displayText = displayText.substr(1); // remove leading space
      displayText = address.flat ? //Add comma after Flat number
      `${displayText.substring(0, address.flat.length)},${displayText.substr(address.flat.length)}`
      : displayText;
      let option = {
          value: address._id,
          label: displayText
      };
      options.push(option);
    });
}
return options;
};

const AutocompleteForm = () => {

    const defaultAddressInfo = {
        flatEstablishment: '',
        buildingNumber: '',
        street: '',
        neighbourhood: '',
        town: '',
        city: '',
        lga: '',
        state: '',
        postcode: ''
    };

    const [retrievedAddresses, setRetrievedAddresses] = useState([]);
    const [addressInfo, setAddressInfo] = useState(defaultAddressInfo);

    const loadOptions = (inputValue, callback) => {
    if(inputValue) {
        axios.get(`${baseApiUrl}/v1/addresses/search?searchText=${inputValue}`)
            .then(res => {
                if (res.status === 200 && res.data.data.length > 0) {
                    setRetrievedAddresses(res.data.data);
                    callback(buildAddressOptions(res.data.data));
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    };

    const fillFormWithResponse = (addressId) => {
        const address = retrievedAddresses.find(addrss => addrss._id === addressId);
        if(address){
            setAddressInfo({
                flatEstablishment: address.flat ? address.flat : address.establishment,
                buildingNumber: address.buildingNumber,
                street: address.street,
                neighbourhood: address.neighbourhood,
                town: address.town,
                city: address.city,
                lga: address.lga,
                state: address.state,
                postcode: address.postcode
            });
        }
    };

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/[^a-zA-Z0-9\s]/g, '');
        return inputValue;
    };

    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#bebeba',
            }
        }
    };
    return(
        <Card body>
            <Card.Title> <h3 className="text-center">Autocomplete address</h3></Card.Title>
            <Form className="mt-3">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Start typing an address or postcode to complete form</Form.Label>
                        <Form.Control
                            as={AsyncSelect}
                            name="address"
                            placeholder="5 John Street  or FC2 4BH"
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            onInputChange={handleInputChange}
                            onChange={(selected) => {
                                const chosen = selected.value;
                                fillFormWithResponse(chosen);
                                return { value: chosen };
                            }}
                            styles={customStyles}
                            custom
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Flat/Establishment</Form.Label>
                        <Form.Control
                            type="text"
                            name="flat"
                            id="flat"
                            value={addressInfo.flatEstablishment || ''}
                            readOnly={true}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Building Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="building-number"
                            id="building-number"
                            value={addressInfo.buildingNumber || ''}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Street</Form.Label>
                        <Form.Control
                            type="text"
                            name="street"
                            id="street"
                            value={addressInfo.street || ''}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Neighbourhood</Form.Label>
                        <Form.Control
                            type="text"
                            name="neighbourhood"
                            id="neighbourhood"
                            value={addressInfo.neighbourhood || ''}
                            readOnly={true}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Town</Form.Label>
                        <Form.Control
                            type="text"
                            name="town"
                            id="town"
                            value={addressInfo.town || ''}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>LGA</Form.Label>
                        <Form.Control
                            type="text"
                            name="lga"
                            id="lga"
                            value={addressInfo.lga}
                            readOnly={true}/>
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            id="state"
                            value={addressInfo.state}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label htmlFor="postal-code">Postcode</Form.Label>
                        <Form.Control
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            value={addressInfo.postcode}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>
            </Form>
        </Card>
    );
};

export default AutocompleteForm;
