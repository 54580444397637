import React, {useState, useEffect} from 'react';
import * as L from 'leaflet';
import { Map, Marker, Popup, TileLayer, GeoJSON, Rectangle } from 'react-leaflet';
import lgaShapes from '../../data/lgaShapes.json';
import {getPostcodeByLga} from '../../utils/apiRequests';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const swapCoordinates = (coordinates) => {
    return coordinates.map(val =>{
        return L.GeoJSON.coordsToLatLng(val);
    });
};

const geoJSONStyle = () => {
   return {
      color: '#1f2021',
      weight: 1,
      fillOpacity: 0.5,
      fillColor: '#fff2af'
}};

const onEachFeature = (feature, layer) => {
      const popupContent = `<Popup>
              <p>LGA name - ${feature.properties.lgaName}</p>
              <p>State name - ${feature.properties.stateName}</p>
              <p>Outward Code - ${feature.properties.stateCode}${feature.properties.lgaCode}</p>
              </Popup>`;
      layer.bindPopup(popupContent);
  };

const mergeLgas = (lgas) => {
  const initial = {
    type : "FeatureCollection",
    features : []
  };

  const res = lgas.reduce((acc, lga) => {
    acc.features.push(lgaShapes[lga].features[0]);
    return acc;
  }, initial);
  return res;
};

const PostcodeViewer = () => {
    const [postcodes, setPostcodes] = useState(null);
    useEffect(() => {
       const fetchData = async () =>{
          // display Abuja Municipal Council LGA
          const fetchedData = await getPostcodeByLga(`${baseApiUrl}/v1/postcodes/lgas/FC6`);
           if(fetchedData){
               setPostcodes(fetchedData);
           }
       };
       fetchData();
    }, []);

    // [x, y] = [lat, lng]
    const abuja = [8.952237117370117, 7.104975659774436];
    const benue = [7.104975659774436, 8.952237117370117];

    return(
        <div>
            <Map center={abuja} zoom={7}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker position={abuja} fillColor="green" markerColor="green">
                    <Popup>Abuja <br/> {abuja}</Popup>
                </Marker>

                <Marker position={benue}>
                    <Popup>Benue <br/> {benue} </Popup>
                </Marker>
                <GeoJSON
                    data={mergeLgas(["FC1", "FC2", "FC3", "FC4", "FC5", "FC6"])}
                    style={geoJSONStyle}
                    onEachFeature={onEachFeature}
                />
                {postcodes && postcodes.map((cell, index) => (
                        <div id={index} key={index}>
                                <Rectangle
                                    bounds={swapCoordinates(cell.cellBlock.coordinates[0])}
                                    color="red"
                                >
                                    <Popup><b>{cell.displayCode}</b></Popup>
                                </Rectangle>
                        </div>
                    ))
                }
            </Map>
        </div>
    );
};

export default PostcodeViewer;
