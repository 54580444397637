import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Redirect, Switch} from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {logout} from './services/AuthService';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import DataEntry from './pages/DataEntry/DataEntry';
import PostcodeViewer from './pages/PostcodeViewer/PostcodeViewer';
import TestViewer from './pages/PostcodeViewer/TestViewer';



const AuthenticatedRoute = ({component: Component, ...rest}) => (
    <Route
      {...rest}
      render= {props => localStorage.getItem("staff") ?
                (<Component {...props} />) :
                (<Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location }
                  }}
                  />)
              }
    />
);

function App(){

  const handleOnIdle = (event) => {
    logout();
  };

  //const { getRemainingTime, getLastActiveTime } = useIdleTimer({
  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: handleOnIdle,
    debounce: 500
  });

  return (
    <Router>
        <div>
            <Switch>
                <Route exact path="/" component={Login} />
                <AuthenticatedRoute path="/demo" component={Home} />
                <AuthenticatedRoute path="/staff/profile" component={Profile} />
                <AuthenticatedRoute path="/display" component={PostcodeViewer} />
                <AuthenticatedRoute path="/display2" component={TestViewer} />
                <AuthenticatedRoute path="/entry" component={DataEntry} />
                <Route component={Login} />}
            </Switch>
        </div>
    </Router>
)
};
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
