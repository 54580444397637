import React from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import eHealthAfricaLogo from '../../assets/img/eHealth_Africa_Logo.svg';
import FIRSLogo from '../../assets/img/FIRSLogo.svg';
import NIMCLogo from '../../assets/img/NIMCLogo.svg';
import MTNLogo from '../../assets/img/MTNLogo.svg';
import GTBLogo from '../../assets/img/GTBLogo.svg';
import AddressValidLogo from '../../assets/img/addressvalid.png';

const CompaniesGallery = () => (
    <section className="client-section">
        <div className="container">
            <div>
                <h3 className="text-right mb-5">To be proudly used by</h3>
            </div>
            <Row>
                <Col xs={6} sm={4} md={2} className="d-flex justify-content-center mb-5">
                    <img src={FIRSLogo} className="img-fluid align-self-center client-logo" alt="FIRSLogo" />
                </Col>
                <Col xs={6} sm={4} md={2} className="d-flex justify-content-center mb-5">
                    <img src={NIMCLogo} className="img-fluid align-self-center client-logo" alt="NIMCLogo" />
                </Col>
                <Col xs={6} sm={4} md={2} className="d-flex justify-content-center mb-5">
                    <img src={eHealthAfricaLogo}
                         className="img-fluid align-self-center client-logo" alt="eHealthAfricaLogo"/>
                </Col>
                <Col xs={6} sm={4} md={2} className="d-flex justify-content-center mb-5">
                    <img src={MTNLogo} className="img-fluid align-self-center client-logo" alt="MTNLogo" />
                </Col>
                <Col xs={6} sm={4} md={2} className="d-flex justify-content-center mb-5">
                    <img src={GTBLogo} className="img-fluid align-self-center client-logo" alt="GTBLogo" />
                </Col>
                <Col xs={6} sm={4} md={2} className="d-flex justify-content-center mb-5">
                    <img src={AddressValidLogo} className="img-fluid align-self-center client-logo" alt="InterswitchLogo" />
                </Col>
            </Row>
        </div>
    </section>
);

export default CompaniesGallery;
