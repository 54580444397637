import React from 'react';
import { useHistory } from 'react-router-dom';
import {getCurrentStaff, logout} from '../../services/AuthService';
import {
    Container, Row, Col,
    Jumbotron, Button
} from 'react-bootstrap';

const Profile = () => {
  let history = useHistory();
  const currentStaff = getCurrentStaff();
  return(
      <div>
        <Container>
          <Row>
            <Col>
              <Jumbotron>
                <h1>
                  <strong>{`${currentStaff.firstName} ${currentStaff.lastName}`}</strong> Profile
                </h1>
                <p>
                  Token: {currentStaff.accessToken}
                </p>
                <p>
                  <Button variant="primary" onClick={() => history.push('/demo')} >Go to Demo page</Button>
                  <Button variant="info"
                    onClick={() => {
                      logout()
                      //history.push('/')
                    }}
                  >
                    Logout
                  </Button>
                </p>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </div>
  );
};

export default Profile;
