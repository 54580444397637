import axios from 'axios';
import authHeader from '../services/AuthHeader'

export const getPostcodeByLatLng = async (url, requestBody) => {
    const data = await axios.post(url, requestBody)
    .then(res => {
        if (res.status === 200) {
            return res.data.data;
        }
        return null;
    })
    .catch(error => {
        console.log(error);
        return null;
    });

    return data;
};

export const registerAddress = async (url, requestBody) => {
    const data = await axios.post(url, requestBody, { headers: authHeader() })
    .then(res => {
      return res.data.message;
    })
    .catch(error => {
        console.log(error);
        return null;
    });

    return data;
};

export const getPostcodeByLga = async (url) => {
    const data = await axios.get(url)
    .then(res => {
        if (res.status === 200) {
            return res.data.data;
        }
        return null;
    })
    .catch(error => {
        console.log(error);
        return null;
    });

    return data;
};
