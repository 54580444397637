const places = {
  'Federal Capital Territory': ['Apo-Dutse', 'Asokoro', 'Bunkoro',
          'Central Area', 'Dakibiyu', 'Dakwo', 'Dape', 'Dawaki', 'Duboyi',
          'Durumi', 'Gaduwa', 'Galadimawa', 'Garki', 'Gudu', 'Guzape',
          'Gwagwalada', 'Gwarinpa', 'Industrial Area', 'Jabi', 'Jahi',
          'Kabusa', 'Kado', 'Kafe', 'Karmo', 'Karu',
          'Katampe', 'Kaura', 'Kubwa', 'Kuje', 'Kukwaba',
          'Kyami', 'Life Camp', 'Lokogoma', 'Lugbe', 'Mabushi',
          'Maitama', 'Mpape', 'Nbora', 'Nyanya', 'Okanje',
          'Pyakasa', 'Saraji', 'Utako', 'Wumba', 'Wupa',
          'Wuse', 'Wuye'
      ],
  'Lagos': ['Agbado Oke-Odo', 'Agboyi Ketu', 'Apapa Iganmu', 'Ayobo Ipaja',
            'Badagry West', 'Bariga', 'Coker Aguda', 'Egbe Idimu', 'Ejigbo',
            'Eredo', 'Eti-Osa East', 'Iba', 'Ifelodun', 'Igando Ikotun',
            'Igbogbo Baiyeku', 'Ijede', 'Ikorodu North', 'Ikorodu West',
            'Ikosi Ejinrin', 'Ikoyi Obalende', 'Imota', 'Iru Victoria Island',
            'Isolo', 'Itire-Ikate', 'Lagos Island East', 'Lekki',
            'Mosan-Okunola', 'Odi-Olowo Ojuwoye', 'Ojodu', 'Ojokoro',
            'Olorunda', 'Onigbongbo', 'Oriade', 'Orile Agege',
            'Oto-Awori', 'Yaba']
};

const generateOptions = (items) => {
    let options = [];
    if(items && items.length > 0){
      items.forEach(item => {
          options.push({
             value:  item,
             label: item
          });
      });
    }
    return options;
};

const buildingTypes = ['Residential', 'Commercial', 'Mixed', 'Government', 'Outdoors', 'Others'];

export const buildingTypeOptions = generateOptions(buildingTypes);
export const getTownOptions = (state) => {
  const towns = places[state];
  return generateOptions(towns);
};

export const townOptions2 = [
 { value: 'Asokoro', label: 'Asokoro' },
 { value: 'Central Business District', label: 'Central Business District' },
 { value: 'Garki Area 1', label: 'Garki Area 1' },
 { value: 'Garki Area 2', label: 'Garki Area 2' },
 { value: 'Garki Area 3', label: 'Garki Area 3' },
 { value: 'Garki Area 7', label: 'Garki Area 7' },
 { value: 'Garki Area 8', label: 'Garki Area 8' },
 { value: 'Garki Area 10', label: 'Garki Area 10' },
 { value: 'Garki Area 11', label: 'Garki Area 11' },
 { value: 'Maitama', label: 'Maitama' },
 { value: 'Three Arms Zone', label: 'Three Arms Zone' },
 { value: 'Wuse Zone 1', label: 'Wuse Zone 1' },
 { value: 'Wuse Zone 2', label: 'Wuse Zone 2' },
 { value: 'Wuse Zone 3', label: 'Wuse Zone 3' },
 { value: 'Wuse Zone 4', label: 'Wuse Zone 4' },
 { value: 'Wuse Zone 5', label: 'Wuse Zone 5' },
 { value: 'Wuse Zone 6', label: 'Wuse Zone 6' },
 { value: 'Wuse Zone 7', label: 'Wuse Zone 7' },
];
