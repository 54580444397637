import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Card, Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const Login = () => {
  let history = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const [loginError, setLoginError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (inputs) => {
    setIsLoading(true);
    axios.post(`${baseApiUrl}/v1/staff/login`, inputs)
    .then(res => {
        setIsLoading(false);
        localStorage.setItem("staff", JSON.stringify(res.data.data));
        history.push('/demo');
    })
    .catch(error => {
      setIsLoading(false);
      setLoginError(error.response.data.message);
    });
  };
  return(
      <div className="vh-100 d-flex justify-content-center align-items-center">
              <Card className="login-card">
                <Card.Header as="h5" className="dark-green-bg text-center text-light">
                  Staff Login
                </Card.Header>
                <Card.Body>
                  <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Row>
                          <Form.Group as={Col}>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                  type="text"
                                  name="email"
                                  id="email"
                                  ref={register({
                                      required: true
                                  })}
                              />
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>
                          <Form.Group as={Col}>
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                  type="password"
                                  name="password"
                                  id="password"
                                  ref={register({
                                      required: true
                                  })}
                              />
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>
                          <Form.Group as={Col}>
                            <Button
                                type = "submit"
                                variant="success"
                                className="mt-3"
                                disabled={isLoading}
                                block>
                                {isLoading &&
                                  <span className="spinner-border spinner-border-sm mr-2"></span>
                                }
                                Login
                            </Button>
                          </Form.Group>
                      </Form.Row>
                      <Form.Row>
                          <Form.Group as={Col}>
                            {Object.keys(errors).length > 0 &&
                              <small className="text-center text-danger">
                                Please enter valid login details
                              </small>
                            }
                            {loginError &&
                              <small className="text-center text-danger">
                                {loginError}
                              </small>
                            }
                          </Form.Group>
                      </Form.Row>
                  </Form>
                </Card.Body>
              </Card>

      </div>
  );
};

export default Login;
