import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import {
    Container, Row, Col,
    Card, Form,
    Button,
    Alert
} from 'react-bootstrap';
import Select from 'react-select';
import {buildingTypeOptions, getTownOptions} from '../../utils/options';
import {getPostcodeByLatLng, registerAddress} from '../../utils/apiRequests';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const DataEntry = () => {

    const { handleSubmit, register, setError, errors, setValue, getValues, reset } = useForm();
    const defaultRetrieveLocationBtnProps = {
        text: 'Retrieve Current Location',
        isDisabled: false
    };

    const defaultRetrievePostcodeBtnProps = {
        text: 'Retrieve Postcode',
        isDisabled: false
    };

    const defaultRegisterBtnProps = {
        text: 'Register',
        isDisabled: false
    };

    const [selectedBuildingType, setSelectedBuildingType] = useState(null);
    const [towns, setTowns] = useState([]);
    const [selectedTown, setSelectedTown] = useState('');
    const [retrieveLocationBtnProps, setRetrieveLocationBtnProps] = useState(defaultRetrieveLocationBtnProps);
    const [retrievePostcodeBtnProps, setRetrievePostcodeBtnProps] = useState(defaultRetrievePostcodeBtnProps);
    const [registerBtnProps, setRegisterBtnProps] = useState(defaultRegisterBtnProps);
    const [pageMessage, setPageMessage] = useState(null);

    const getCurrentLocation = () => {
        setRetrieveLocationBtnProps({
            text: <span><span className="spinner-border spinner-border-sm"></span> Retrieving</span>,
             isDisabled: true
        });

        if( navigator.geolocation) {
            const options = { enableHighAccuracy: true, timeout: 30000 };
            navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError, options);
        } else {
            setPageMessage({
                type: 'danger',
                message: 'Please enable your device location and try again.'
            });
            setRetrieveLocationBtnProps(defaultRetrieveLocationBtnProps);
        }
    };

    const onLocationSuccess = ({coords}) => {
        setValue('lat', coords.latitude);
        setValue('lng', coords.longitude);
        setRetrieveLocationBtnProps(defaultRetrieveLocationBtnProps);
    };

    const onLocationError = (error) => {
        setPageMessage({
           type: 'danger',
           message: error.message
        });
        setRetrieveLocationBtnProps(defaultRetrieveLocationBtnProps);
    };

    const getPostcodeInfo = async () => {

        const { lat, lng } = getValues();
        if(!(lat && lng)){
          setError("lat", {
            type: "manual",
            message: "Please enter latitude"
          });
          setError("lng", {
            type: "manual",
            message: "Please enter latitude"
          });
          return;
        }

        setRetrievePostcodeBtnProps({
            text: <span><span className="spinner-border spinner-border-sm"></span> Retrieving</span>,
            isDisabled: true
        });

        const requestBody = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };
        const fetchedData = await getPostcodeByLatLng(`${baseApiUrl}/v1/postcodes/retrieve` , requestBody);
        if(fetchedData){
            setValue('postcode', fetchedData.postcode);
            setValue('lga', fetchedData.lga);
            setValue('state', fetchedData.state);
            setTowns(getTownOptions(fetchedData.state));
        }else{
            setPageMessage({
               type: 'danger',
               message: 'Postcode could not be found for this place'
           });
        }
        setRetrievePostcodeBtnProps(defaultRetrievePostcodeBtnProps);
    };

    const onSubmit = async (inputs) => {
        setRegisterBtnProps({
            text: <span><span className="spinner-border spinner-border-sm"></span> Registering</span>,
            isDisabled: true
        });
        const requestBody = {
            ...inputs,
            lat: parseFloat(inputs.lat),
            lng: parseFloat(inputs.lng)
        };

        const response = await registerAddress(`${baseApiUrl}/v1/addresses` , requestBody);
        if(response){
            setPageMessage({
               type: 'success',
               message: response
           });
        }else {
            setPageMessage({
               type: 'danger',
               message: 'Address registration failed please review form and try again'
           });
        }

        setRegisterBtnProps(defaultRegisterBtnProps);
    };

    useEffect(() => {
        register({ name: 'buildingType'}, {required: true });
        register({ name: 'town' });
     }, [register]);

    return (
        <Container className="mt-2">
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header as="h5" className="dark-green-bg text-center text-light">
                          Address Registration Form
                        </Card.Header>
                        <Card.Body>
                            <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" className="align-self-center mt-3">
                                        <Button
                                            type = "button"
                                            variant="success"
                                            className="mt-3"
                                            onClick={getCurrentLocation}
                                            block>
                                            {retrieveLocationBtnProps.text}
                                        </Button>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lat"
                                            id="lat"
                                            ref={register({
                                                required: true,
                                                pattern: /^-?\d*(\.\d+)?$/i
                                            })}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lng"
                                            id="lng"
                                            ref={register({
                                                required: true,
                                                pattern: /^-?\d*(\.\d+)?$/i
                                            })}
                                            disabled
                                        />
                                    </Form.Group>

                                    <Col md={{ span: 4, offset: 4 }}>
                                        {errors.lat && <small className="text-danger">
                                            Enter a valid latitude
                                        </small>}
                                    </Col>
                                    <Col md={4}>
                                        {errors.lng && <small className="text-danger">
                                            Enter a valid longitude
                                        </small>}
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="4" className="align-self-center mt-3">
                                        <Button
                                            type="button"
                                            variant="success"
                                            className="mt-3"
                                            onClick={getPostcodeInfo}
                                            block>
                                            {retrievePostcodeBtnProps.text}
                                        </Button>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label>Postcode</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="postcode"
                                            id="postcode"
                                            ref={register({
                                                required: true,
                                                pattern: /^[a-zA-Z]{2}\d{1,2}\s\d{1,2}[a-zA-Z]{2}$/i
                                            })}
                                            disabled
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Row>
                                    <Col md={{ span: 4, offset: 4 }}>
                                        {errors.postcode && <small className="text-danger">
                                            Enter a valid postcode
                                        </small>}
                                    </Col>
                                </Row>

                                <Form.Row>
                                    <Form.Group as={Col} sm={12}>
                                        <Form.Label htmlFor="buildingType">Building Type</Form.Label>
                                        <Form.Control
                                            as={Select}
                                            name="buildingType"
                                            value={selectedBuildingType}
                                            onChange={(selected, actionMeta) => {
                                                setSelectedBuildingType(selected);
                                                setValue(actionMeta.name, selected.value);
                                                return { value: selected.value };
                                            }}
                                            options={buildingTypeOptions}
                                            custom/>
                                    </Form.Group>
                                    <Col sm={12}>
                                        {errors.buildingType && <small className="text-danger">
                                            Select a building type
                                        </small>}
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Flat/Establishment</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="flat"
                                            id="flat"
                                            ref={register}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Building Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="buildingNumber"
                                            id="buildingNumber"
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>Street</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="street"
                                            id="street"
                                            ref={register}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md={6}>
                                        <Form.Label htmlFor="neighbourhood">Neighbourhood</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="neighbourhood"
                                            id="neighbourhood"
                                            ref={register}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6}>
                                        <Form.Label htmlFor="town">Town</Form.Label>
                                            <Form.Control
                                                as={Select}
                                                name="town"
                                                value={selectedTown}
                                                onChange={(selected, actionMeta) => {
                                                    setSelectedTown(selected);
                                                    setValue(actionMeta.name, selected.value);
                                                    return { value: selected.value };
                                                }}
                                                options={towns}
                                                custom
                                             />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>LGA</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lga"
                                            id="lga"
                                            ref={register({ required: true })}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="state"
                                            id="state"
                                            ref={register({ required: true })}
                                            disabled
                                        />
                                    </Form.Group>
                                </Form.Row>
                                {pageMessage && (
                                    <Row>
                                        <Col>
                                            <Alert variant={pageMessage.type}>
                                               {pageMessage.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                <Form.Row>
                                    <Form.Group as={Col} md="6" className="align-self-center mt-3">
                                        <Button
                                            type="reset"
                                            variant="danger"
                                            className="mt-3"
                                            onClick={() => {
                                              reset();
                                              setSelectedBuildingType(null);
                                              setSelectedTown('');
                                              setTowns([]);
                                              setPageMessage(null);
                                            }}
                                            block>
                                            Cancel
                                        </Button>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className="align-self-center mt-3">
                                        <Button
                                            type="submit"
                                            variant="success"
                                            className="mt-3"
                                            block>
                                            {registerBtnProps.text}
                                        </Button>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DataEntry;
