import React, {useState, useRef} from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import {
    Col,
    Button,
    Card,
    Form
} from 'react-bootstrap';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

const postcodeRegex = /^[a-zA-Z]{2}\d{1,2}\s\d{1,2}[a-zA-Z]{2}$/i;

const PostcodeForm = () => {
    const defaultRetrieveBtnProps = {
        text: 'Retrieve',
        isDisabled: false
    };
    const defaultAddressInfo = {
        flatEstablishment: '',
        buildingNumber: '',
        street: '',
        neighbourhood: '',
        town: '',
        city: '',
        lga: '',
        state: '',
        postcode: ''
    };

    const addressSelectRef = useRef();
    const { handleSubmit, register, errors, reset } = useForm();
    const [retrieveBtnProps, setRetrieveBtnProps] = useState(defaultRetrieveBtnProps);
    const [retrievedAddresses, setRetrievedAddresses] = useState([]);
    const [addressOptions, setAddressOptions] = useState([]);
    const [addressInfo, setAddressInfo] = useState(defaultAddressInfo);

    const onSubmit = async (inputs) => {
        setRetrieveBtnProps({
            text: <span><span className="spinner-border spinner-border-sm"></span> Retrieving</span>,
            isDisabled: true
        });
        setAddressInfo(defaultAddressInfo);
        axios.get(`${baseApiUrl}/v1/addresses/searchByPostalCode?postcode=${inputs.postalCode}`)
            .then(res => {
                if (res.status === 200) {
                    setRetrievedAddresses(res.data.data);
                    const addrOpts = buildAddressOptions(res.data.data);
                    setAddressOptions(addrOpts);
                    addressSelectRef.current.onMenuOpen();
                }
                setRetrieveBtnProps(defaultRetrieveBtnProps);
                reset({ postalCode: '' });
            })
            .catch(error => {
                setRetrievedAddresses([]);
                setAddressOptions([]);
                setRetrieveBtnProps(defaultRetrieveBtnProps);
                reset({ postalCode: '' });
                console.log(error);
            });
    };

    const buildAddressOptions = (addressResponse) => {
        let options = [];
        addressResponse.forEach(address => {
            let streetArea =  address.street || address.neighbourhood;
            let displayText = [address.flat, address.buildingNumber, streetArea, address.town]
                              .filter(Boolean)
                              .reduce((a,b) => `${a} ${b}`, '');
            displayText += `, ${address.state}`;
            displayText = displayText.substr(1); // remove leading space
            displayText = address.flat ? //Add comma after Flat number
            `${displayText.substring(0, address.flat.length)},${displayText.substr(address.flat.length)}`
            : displayText;
            let option = {
                value: address._id,
                label: displayText
            };
            options.push(option);
        });
        return options;
    };

    const fillFormWithResponse = (addressId) => {
        const address = retrievedAddresses.find(addrss => addrss._id === addressId);
        if(address){
            setAddressInfo({
                flatEstablishment: address.flat || address.establishment,
                buildingNumber: address.buildingNumber,
                street: address.street,
                neighbourhood: address.neighbourhood,
                town: address.town,
                city: address.city,
                lga: address.lga,
                state: address.state,
                postcode: address.postcode
            });
        }
    };

    return(
        <Card body>
            <Card.Title> <h3 className="text-center">Retrieve address by postcode</h3></Card.Title>
            <Form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                            type="text"
                            name="postalCode"
                            id="postal-code"
                            placeholder="FC2 4BH"
                            ref={register({
                                required: true, minLength: 6, maxLength: 8,
                                pattern: {
                                    value: postcodeRegex,
                                    message: "Enter a valid a postcode."
                                }
                            })}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="align-self-center mt-3">
                        <Button
                            type = "submit"
                            variant="success"
                            className="mt-3"
                            block>
                            {retrieveBtnProps.text}
                        </Button>
                    </Form.Group>
                    <Col>
                        {errors.postalCode && <small className="text-danger">
                            Enter a valid postcode
                        </small>}
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label htmlFor="addresses">Select Address</Form.Label>
                        <Form.Control
                            as={Select}
                            name="addresses"
                            ref={(e) => {
                                register(e);
                                addressSelectRef.current = e;
                            }}
                            onChange={(selected) => {
                                const chosen = selected.value;
                                fillFormWithResponse(chosen);
                                return { value: chosen };
                            }}
                            options={addressOptions}
                            custom/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Flat/Establishment</Form.Label>
                        <Form.Control
                            type="text"
                            name="flat"
                            id="flat"
                            value={addressInfo.flatEstablishment || ''}
                        readOnly={true}/>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Building Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="building-number"
                            id="building-number"
                            value={addressInfo.buildingNumber || ''}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Street</Form.Label>
                        <Form.Control
                            type="text"
                            name="street"
                            id="street"
                            value={addressInfo.street || ''}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Neighbourhood</Form.Label>
                        <Form.Control
                            type="text"
                            name="neighbourhood"
                            id="neighbourhood"
                            value={addressInfo.neighbourhood || ''}
                            readOnly={true}/>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Town</Form.Label>
                        <Form.Control
                            type="text"
                            name="town"
                            id="town"
                            value={addressInfo.town || ''}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>LGA</Form.Label>
                        <Form.Control
                            type="text"
                            name="lga"
                            id="lga"
                            value={addressInfo.lga}
                            readOnly={true}/>
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            name="state"
                            id="state"
                            value={addressInfo.state}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label htmlFor="postal-code">Postcode</Form.Label>
                        <Form.Control
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            value={addressInfo.postcode}
                            readOnly={true}/>
                    </Form.Group>
                </Form.Row>

                {/*<Row form className="justify-content-center mt-5">*/}
                {/*    <Col md={6}>*/}
                {/*        <Button color="success" block>Submit</Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </Form>
        </Card>
    );
};

export default PostcodeForm;
