import React from 'react';
import {
    Container, Row, Col,
    Navbar, Nav,
    Button
} from 'react-bootstrap';
import AddressValidLogo from '../../assets/img/addressvalid.png';
import {logout} from '../../services/AuthService';
import PostcodeForm from "./PostcodeForm";
import AutocompleteForm from "./AutocompleteForm";
import CompaniesGallery from "./CompaniesGallery";

const Home = () => {
        return (
        <div>
            <Navbar collapseOnSelect expand="lg" variant="light" className="header-section">
                <Container>
                    <Navbar.Brand href="/" className="nav-brand">
                        <span className="d-inline-block align-top">
                            <img src={AddressValidLogo} className="d-none d-sm-block" alt="AddressValidLogo" />
                        </span>AddressValid
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="nav-header ml-auto mr-lg-5">
                            <Nav.Link href="#">About</Nav.Link>
                            <Nav.Link href="#">Pricing</Nav.Link>
                            <Nav.Link href="#">Documentation</Nav.Link>
                            <Nav.Link href="/entry">Data Entry</Nav.Link>
                        </Nav>
                        <Nav>
                            {/**<Button variant="outline-success">Sign In</Button>*/}
                            <Button variant="outline-success"
                            onClick={() => {
                              logout()
                            }}>Log out</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <section className="hero-section">
                <Container>
                    <Row className="mt-2 mb-5">
                        <Col className="text-white text-center">
                            <h1>Capture the right address every time</h1>
                            <p className="lead">
                                We make your customer address entry and verification process easy and consistent by preventing
                                invalid address input from spelling mistakes or incorrect street extensions.
                            </p>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <PostcodeForm/>
                        </Col>

                        <Col md={6} className="mt-3 mt-sm-3 mt-md-0">
                            <AutocompleteForm/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <CompaniesGallery/>
        </div>
    );
};

export default Home;
