import React from 'react';
import * as L from 'leaflet';
import { Map, Marker, Popup, TileLayer, GeoJSON, Rectangle} from 'react-leaflet';
import lgaShapes from '../../data/lgaShapes.json';
import cellBlocks from '../../data/etiosa.json';
//import ehaNigeria from '../../data/ehaNigeria.json';


const swapCoordinates = (coordinates) => {
    return coordinates.map(val =>{
        return L.GeoJSON.coordsToLatLng(val);
    });
};

const geoJSONStyle = () => {
   return {
      color: '#45aa10',
      weight: 1,
      fillOpacity: 0.5,
      fillColor: '#fff2af'
}};

const onEachFeature = (feature, layer) => {
    const popupContent = `<Popup>
              <p>LGA name - ${feature.properties.lgaName}</p>
              <p>State name - ${feature.properties.stateName}</p>
              <p>Outward Code - ${feature.properties.stateCode}${feature.properties.lgaCode}</p>
            </Popup>`;
    layer.bindPopup(popupContent);
};

const mergeLgas = (lgas) => {
  const initial = {
    type : "FeatureCollection",
    features : []
  };

  const res = lgas.reduce((acc, lga) => {
    acc.features.push(lgaShapes[lga].features[0]);
    return acc;
  }, initial);
  return res;
};


const TestViewer = () => {

    // [x, y] = [lat, lng] = [6.4621373, 3.4544241]
    const lekkiLagos = [6.4501983, 3.4535067];

    return(
        <div>
            <Map center={lekkiLagos} zoom={12}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                <Marker position={lekkiLagos} fillColor="green" markerColor="green">
                    <Popup>Lekki <br/> {lekkiLagos}</Popup>
                </Marker>
                <GeoJSON
                    data={mergeLgas(["LA11", "LA14", "LA15", "LA18", "LA20"])}
                    //data={lgaShapes.FC6}
                    style={geoJSONStyle}
                    onEachFeature={onEachFeature}
                />

                {cellBlocks && cellBlocks.map((cell, index) => (
                        <div id={index} key={index}>
                                <Rectangle
                                    bounds={swapCoordinates(cell.cellBlock.coordinates[0])}
                                    color="red"
                                >
                                    <Popup><b>{cell.displayCode}</b> <br/> {cell.cellBlock.coordinates[0][0]}</Popup>
                                </Rectangle>
                        </div>
                    ))
                }
            </Map>
        </div>
    );
};

export default TestViewer;
